import { useTranslation } from '@cms/i18n';
import { MatchEvent } from '@common/types/MatchEvent';

import { PlayerIDMap } from '../../PlayerIDMap';
import { PlayerLink } from './PlayerLink';

interface SecondPlayerProps {
    event: MatchEvent;
    playerIDMap: PlayerIDMap;
}

export const SecondPlayer = ({ event, playerIDMap }: SecondPlayerProps) => {
    const __livescores = useTranslation('livescores').t;
    const playerID =
        ('outgoingPlayerID' in event && event.outgoingPlayerID) || ('assistID' in event && event.assistID);

    if (playerID) {
        return <PlayerLink playerID={playerID} playerIDMap={playerIDMap} />;
    }

    return __livescores`match.unknownPlayer`;
};
