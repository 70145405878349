import { FC } from 'react';

import { Translate, useTranslation } from '@cms/i18n';
import { Match, MatchPeriod, ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { OddsBlock } from '@web/atoms/match/OddsBlock';
import { ScoreElement } from '@web/atoms/match/ScoreElement';
import { StatusElement } from '@web/atoms/match/StatusElement';
import { TeamElement } from '@web/atoms/match/TeamElement';
import { WinsBlock } from '@web/atoms/match/WinsBlock';
import { SponsoredPartnerLogo } from '@web/atoms/SponsoredPartnerLogo';
import { StaticImage } from '@web/atoms/StaticImage';
import { Route } from '@web/routing';

import { PlatformToggle } from '../PlatformToggle';

import styles from './MatchHeader.module.scss';

export default interface Props {
    match: Match;
    showOdds: boolean;
}

const isToday = (matchDate: Date): boolean => {
    const today = new Date();
    return (
        matchDate.getDate() === today.getDate() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getFullYear() === today.getFullYear()
    );
};

const getFormattedMatchDate = (dateString: string, __livescores: Translate): string => {
    const date: Date = new Date(dateString);
    const dateDayBefore = new Date(new Date().setDate(date.getDate() - 1));

    if (isToday(date)) {
        return __livescores`match.today` + ' ' + DateTimeUtil.format(date, Format.TIME_NUMERIC);
    } else if (isToday(dateDayBefore)) {
        return __livescores`match.tomorrow` + ' ' + DateTimeUtil.format(date, Format.TIME_NUMERIC);
    } else {
        return DateTimeUtil.format(date, Format.DATE_DAY_MONTH);
    }
};

export const MatchHeader: FC<Props> = ({ match, showOdds }) => {
    const __livescores = useTranslation('livescores').t;
    const { platform, allowAds, modulesBySection } = useContextData();

    if (!showOdds) match.odds = [];

    const hasPartner = Boolean(allowAds && match.competition?.tag);

    const competition = match.competition?.tag;
    if (competition) {
        const sponsorships = modulesBySection?.[ModuleScheduleSection.TAG_SPONSORSHIP];
        sponsorships?.forEach((sponsorship) => {
            if (sponsorship.tagID === competition.tagID) {
                competition.partner = sponsorship.bookmaker;
            }
        });
    }

    const MatchTime = () => (
        <div className={styles['match-time']}>
            {!match.timeUnknown && match.period === MatchPeriod.NOT_STARTED ? (
                getFormattedMatchDate(match.kickOff, __livescores)
            ) : (
                <StatusElement match={match} />
            )}
        </div>
    );

    return (
        <>
            {hasPartner && (
                <SponsoredPartnerLogo
                    hasBackgroundImage
                    hasDarkBackground
                    hasLink
                    tag={match.competition?.tag}
                />
            )}
            <div className={styles.MatchHeader}>
                <PlatformToggle exclude={PlatformID.VI}>
                    <StaticImage
                        className={styles.background}
                        src="/images/stadion.jpg"
                        width="1280"
                        height="320"
                        alt=""
                    />
                </PlatformToggle>
                <div className={styles['match-details']}>
                    <div className={`${styles['recent-form']} ${styles['home']}`}>
                        {match.home ? (
                            <TeamElement
                                element="div"
                                match={match}
                                team={match.home}
                                logoAlignment={'left'}
                                isLarge
                            />
                        ) : null}
                        <WinsBlock class={'home'} optaID={match.home?.id} matches={match.resultsHome || []} />
                    </div>
                    <div className={styles['match-score']}>
                        <ScoreElement match={match} isHorizontal hasDarkBackground />
                        <PlatformToggle include={PlatformID.VI}>
                            <div className={styles['match-location']}>
                                <MatchTime />
                            </div>
                        </PlatformToggle>
                    </div>
                    <div className={`${styles['recent-form']} ${styles['away']}`}>
                        {match.away ? (
                            <TeamElement element="div" match={match} team={match.away} isLarge />
                        ) : null}
                        <WinsBlock class={'away'} optaID={match.away?.id} matches={match.resultsAway || []} />
                    </div>
                </div>
                <div className={styles['match-location']}>
                    {platform.id === PlatformID.VI ? (
                        <>
                            {match.homeExpectedGoals || match.awayExpectedGoals ? (
                                <>
                                    <span
                                        className={styles['expected-goals-text']}
                                    >{__livescores`match.expectedGoals`}</span>
                                    <span className={`tag ${styles['expected-goals-tag']}`}>
                                        {match.homeExpectedGoals || 0} - {match.awayExpectedGoals || 0}
                                    </span>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <MatchTime />
                    )}
                </div>

                {showOdds ? (
                    <div className={styles['match-odds']}>
                        <hr />
                        <OddsBlock
                            match={match}
                            hasDarkBackground
                            isLarger
                            trackerName={`${ModuleScheduleSection.ODDS_MATCHES}Detail`}
                            section={ModuleScheduleSection.ODDS_MATCHES}
                            route={Route.MatchByID}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};
