import { useTranslation } from '@cms/i18n';
import { MatchEvent } from '@common/types/MatchEvent';

import { PlayerIDMap } from '../../PlayerIDMap';
import { PlayerLink } from './PlayerLink';

interface FirstPlayerProps {
    event: MatchEvent;
    playerIDMap: PlayerIDMap;
}

export const FirstPlayer = ({ event, playerIDMap }: FirstPlayerProps) => {
    const __livescores = useTranslation('livescores').t;
    const playerID =
        ('ingoingPlayerID' in event && event.ingoingPlayerID) || ('playerID' in event && event.playerID);

    if (playerID) {
        return <PlayerLink playerID={playerID} playerIDMap={playerIDMap} />;
    }

    return __livescores`match.unknownPlayer`;
};
