import { FC, PropsWithChildren, ReactElement } from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { InternalLink, InternalLinkProps } from '@web/atoms/InternalLink';

import styles from './ComponentHead.module.scss';

export interface Props extends PropsWithChildren {
    title?: string | JSX.Element;
    subTitle?: string | JSX.Element;
    titleBorder?: string;
    link?: InternalLinkProps;
    hasBottomMargin?: boolean;
}

export const ComponentHead: FC<Props> = ({
    title,
    subTitle,
    titleBorder,
    link,
    hasBottomMargin,
    children,
}): ReactElement => {
    const __actions = useTranslation('actions').t;
    return (
        <div className={`${styles.ComponentHead} ${hasBottomMargin ? styles['margin-bottom'] : ''}`}>
            {subTitle ? <h3>{subTitle}</h3> : null}
            {title ? <h2 className={titleBorder ? styles[titleBorder] : ''}>{title}</h2> : null}
            {link ? (
                <InternalLink {...link}>
                    {__actions`more`}
                    <Icon.right />
                </InternalLink>
            ) : null}
            {children}
        </div>
    );
};
