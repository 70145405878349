import { FC } from 'react';
import dynamic from 'next/dynamic';

import { useTranslation } from '@cms/i18n';
import { Match, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { ComponentHead } from '@web/molecules/ComponentHead';
import { MatchHeader } from '@web/molecules/MatchHeader';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import StatsTableStyle from '@web/molecules/StatsTable/StatsTable.module.scss';
import { TabbedContent } from '@web/molecules/TabbedContent';
import { MatchStatistics } from '@web/organisms/MatchStatistics';
import { MatchTimeLine } from '@web/organisms/MatchTimeLine';

import { DetailsBlock } from './molecules/DetailsBlock';
import { TeamSetup } from './molecules/TeamSetup';

import styles from './MatchDetailSection.module.scss';

const HtmlToReact = dynamic(() => import('@web/atoms/HtmlToReact').then((mod) => mod.HtmlToReact));
const FootballFormation = dynamic(() =>
    import('@web/molecules/FootballFormation').then((mod) => mod.FootballFormation),
);

interface Props {
    match: Match;
    showOdds: boolean;
}

export const MatchDetailSection: FC<Props> = ({ match, showOdds }) => {
    const { platform } = useContextData();
    const __livescores = useTranslation('livescores').t;

    const tabs = [
        {
            title: __livescores`match.tabs.match`,
            content: <MatchTimeLine match={match} />,
        },
    ];

    if (!match.details?.homeStartingPlayers && !match.details?.awayStartingPlayers) {
        tabs.push({
            title: __livescores`match.tabs.setup`,
            content: <p className={styles.LineUp}>{__livescores`match.lineup`}</p>,
        });
    } else if (match.details?.homeFormation && match.details?.awayFormation) {
        tabs.push({
            title: __livescores`match.tabs.setup`,
            content: (
                <>
                    <FootballFormation match={match} />
                    <br />
                    <div className={StatsTableStyle.HalfWidth}>
                        <ComponentHead title={match.home?.name} />
                        {match.details.homeCoach ? (
                            <PlatformToggle include={PlatformID.VI}>
                                <TeamSetup
                                    players={[match.details.homeCoach!]}
                                    title={__livescores`match.coach`}
                                    isCoach={true}
                                    shirt={match.home?.shirt}
                                    isHomeTeam={true}
                                />
                            </PlatformToggle>
                        ) : null}
                        <TeamSetup
                            players={match.details?.homeBenchPlayers}
                            title={__livescores`match.substitutes`}
                            events={match.details.events}
                            shirt={match.home?.shirt}
                            isHomeTeam={true}
                        />
                    </div>
                    <div className={StatsTableStyle.HalfWidth}>
                        <ComponentHead title={match.away?.name} />
                        {match.details.awayCoach ? (
                            <PlatformToggle include={PlatformID.VI}>
                                <TeamSetup
                                    players={[match.details.awayCoach]}
                                    title={__livescores`match.coach`}
                                    isCoach={true}
                                    shirt={match.away?.shirt}
                                />
                            </PlatformToggle>
                        ) : null}
                        <TeamSetup
                            players={match.details?.awayBenchPlayers}
                            title={__livescores`match.substitutes`}
                            events={match.details.events}
                            shirt={match.away?.shirt}
                        />
                    </div>
                </>
            ),
        });
    } else if (match.details?.homeStartingPlayers && match.details?.awayStartingPlayers) {
        tabs.push({
            title: __livescores`match.tabs.setup`,
            content: (
                <>
                    <div className={StatsTableStyle.HalfWidth}>
                        <ComponentHead title={match.home?.name} />
                        {match.details.homeCoach ? (
                            <PlatformToggle include={PlatformID.VI}>
                                <TeamSetup
                                    players={[match.details.homeCoach!]}
                                    title={__livescores`match.coach`}
                                    isCoach={true}
                                    shirt={match.home?.shirt}
                                    isHomeTeam={true}
                                />
                            </PlatformToggle>
                        ) : null}
                        <TeamSetup
                            players={match.details?.homeStartingPlayers}
                            title={__livescores`match.base`}
                            events={match.details.events}
                            shirt={match.home?.shirt}
                            isHomeTeam={true}
                        />
                        <TeamSetup
                            players={match.details?.homeBenchPlayers}
                            title={__livescores`match.substitutes`}
                            events={match.details.events}
                            shirt={match.home?.shirt}
                            isHomeTeam={true}
                        />
                    </div>
                    <div className={StatsTableStyle.HalfWidth}>
                        <ComponentHead title={match.away?.name} />
                        {match.details.awayCoach ? (
                            <PlatformToggle include={PlatformID.VI}>
                                <TeamSetup
                                    players={[match.details.awayCoach]}
                                    title={__livescores`match.coach`}
                                    isCoach={true}
                                    shirt={match.away?.shirt}
                                />
                            </PlatformToggle>
                        ) : null}
                        <TeamSetup
                            players={match.details?.awayStartingPlayers}
                            title={__livescores`match.base`}
                            events={match.details.events}
                            shirt={match.away?.shirt}
                        />
                        <TeamSetup
                            players={match.details?.awayBenchPlayers}
                            title={__livescores`match.substitutes`}
                            events={match.details.events}
                            shirt={match.away?.shirt}
                        />
                    </div>
                </>
            ),
        });
    }

    if (match.details?.statistics) {
        tabs.push({
            title: __livescores`match.tabs.statistics`,
            content: <MatchStatistics platform={platform.id} statistics={match.details.statistics} />,
        });
    }

    if (platform.id === PlatformID.VI && match.report) {
        tabs.push({
            title: __livescores`match.tabs.summary`,
            content: (
                <div className={styles['match-report-section']}>
                    {match.report.title ? <h4>{match.report.title}</h4> : null}
                    <HtmlToReact html={match.report.content || ''} />
                </div>
            ),
        });
    }

    return (
        <div className={styles.MatchDetailSection} data-testid="match-detail-section">
            <MatchHeader match={match} showOdds={showOdds} />
            <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_1} />
            <TabbedContent subtitle={false} isPrimary={true} tabs={tabs} />
            <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_2} />
            <h3>{__livescores`match.matchDetails`}</h3>
            <hr />
            <DetailsBlock match={match} />
        </div>
    );
};
